<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    //- 表格
    table.col-12
      .card
        .card-body
          .header-row.mb-3
            .header-title 節目表列表
            b-button.width-lg(variant="primary" to="/program-schedule/create") 新增節目表
          //- Table
          .table-responsive.mb-0
            b-table(
              key="LiveProgramList"
              ref="LiveProgramList"
              :items="getLiveProgramList", 
              :fields="LiveProgramListFields", 
              responsive, 
              :per-page="perPage", 
              :current-page="currentPage", 
            )
              //- 啟用
              template(v-slot:cell(status)="row")
                b-form-checkbox(
                  switch
                  :key="'ProgramItemStatus-' + row.item.id"
                  v-model="row.item.status",
                  :name="'ProgramItemStatus' + row.item.id",
                  :value="true",
                  :unchecked-value="false"
                  @change="resetScheduleItem(row.item.id)"
                ) 

              template(v-slot:cell(start_date)="row")
                span {{ row.item.start_date }}
                br
                span {{ row.item.start_time }}

              //- 編輯
              template(v-slot:cell(edit)="row")
                b-button.table-btn.mr-2(:to="'/program-schedule/' + row.item.id")
                  | 編輯
                b-button.table-btn.mr-2(@click="copyLiveProgramList(row.item.id)")
                  | 複製
                b-button.table-btn(@click="removeProgramItem(row.item.id)")
                  | 刪除

          .row.mb-md-2
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
      .card
        .card-body
          .header-row.mb-3
            .header-title 目前啟用節目表
            b-button.width-md.btn-rounded(variant="primary" size="sm") 
              span {{ $root.common.getNowTime('date') }} 
              span ({{ $root.common.getNowTime('week2') }})
          //- Table
          .table-responsive.mb-0
            b-table(
              key="CurrentLiveProgramList"
              ref="CurrentLiveProgramList"
              :items="getCurrentLiveProgramList", 
              :fields="CurrentLiveProgramFields", 
              responsive,
            )
              template(v-slot:cell(status)="row")
                b-badge.badge-outline-dark(v-if="row.item.status") 尚未播畢
                b-badge.badge-outline-success(v-else) 已經播畢

              template(v-slot:cell(image)="row")
                img.post-img(:src="row.item.image")
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Switches from "vue-switches";

/**
 * Starter component
 */
export default {
  name: 'ProgramScheduleList',
  data () {
    return {
      title: "節目表",
      items: [
        {
          text: "直播管理",
          href: "/",
        },
        {
          text: "專區首頁編輯",
          href: "/livestream",
        },
        {
          text: "節目表",
          active: true,
        }
      ],
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      LiveProgramListFields: [
        { key: "name", label: "節目表名稱", sortable: false, class: 'width-auto' },
        { key: "status", label: "啟用", sortable: false, class: 'width-auto center' },
        { key: "editer.name", label: "更新人員", sortable: false, class: 'width-auto center' },
        { key: "update_at", label: "更新日期", sortable: false, class: 'width-auto center' },
        { key: "start_date", label: "自動啟用時間", sortable: false, class: 'width-auto center' },
        { key: "edit", label: "操作", sortable: false, class: 'width-auto center' },
      ],
      CurrentLiveProgramFields: [
        { key: "start_time", label: "時段", sortable: false, },
        { key: "image", label: "封面", sortable: false, },
        { key: "program_name", label: "節目名稱", sortable: false, },
        { key: "status", label: "狀態", sortable: false, },
      ],
    };
  },
  computed: {

  },
  methods: {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Switches
  },
  methods: {
    // 重新刷新目前播放節目清單
    reloadCurrentLiveProgramList () {
      this.$refs.CurrentLiveProgramList.refresh()
    },
    // 重新刷新所有節目播放清單
    reloadLiveProgramList () {
      this.$refs.LiveProgramList.refresh()
    },
    // 獲得目前播放節目清單
    async getCurrentLiveProgramList (_table, _callback) {
      let vm = this
      await this.$root.apis.getCurrentLiveProgramList(async function (_response) {
        let programs = _response.body.data

        let currentTime = vm.$root.common.getNowTime('time')
        programs.forEach(function (_item, _index) {
          programs[_index].status = 0
          if (_item.start_time >= currentTime) {
            programs[_index].status = true
          }
        })

        if (typeof _callback === 'function') {
          await _callback(programs)
        }
      })
    },
    // 獲得所有節目播放清單
    getLiveProgramList (_table, _callback) {
      let vm = this
      let search = { page: this.currentPage }
      this.$root.apis.getLiveProgramList(search,
        async function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            await _callback(response.items)
          }
        })
    },
    // 直接啟用某個直播節目清單
    resetScheduleItem (_ProgramHashID) {
      let vm = this
      this.$root.apis.setCurrentLiveProgramList(_ProgramHashID, function () {
        vm.$root.common.showSingleNotify("直播節目表手動啟用成功！")
        vm.reloadCurrentLiveProgramList()
        vm.reloadLiveProgramList()
      })
    },
    // 移除某個直播節目表
    removeProgramItem (_ProgramHashID) {
      let vm = this
      this.$root.common.confirmAction(
        "移除直播節目播放清單",
        "你確定要移除這個直播節目表嗎？",
        "確定移除",
        "下次再說",
        function () {
          vm.$root.apis.delLiveProgramList(_ProgramHashID, function () {
            vm.$root.common.showSingleNotify("直播節目表移除成功！")
            vm.reloadCurrentLiveProgramList()
            vm.reloadLiveProgramList()
          })
        }
      )
    },
    // 複製某個直播節目表
    copyLiveProgramList (_ProgramHashID) {
      let vm = this
      vm.$root.apis.copyLiveProgramList(_ProgramHashID, function () {
        vm.$root.common.showSingleNotify("直播節目表複製成功！")
        vm.reloadCurrentLiveProgramList()
        vm.reloadLiveProgramList()
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .vue-switcher--bold div
  top: 0 !important
.post-img
  width: 94px
</style>
